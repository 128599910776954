/* region Default */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #004877;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #004877;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* endregion */

/* region Customized*/

.main-content {
  width: 1240px; /* Assuming 20px padding on both sides */
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box; /* Include padding in the element's total width */
}

header {
  background-color: #004877; /* Adjust the color to match your design */
  border-bottom: 1px solid #EAEAEA; /* This adds a subtle line under the header */
  padding: 10px 20px; /* Adjust padding to match your design */
  display: flex;
  justify-content: center;
  /*margin: 0 auto;*/
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
}

footer {
  background-color: #004877; /* Adjust the color to match your design */
  border-bottom: 1px solid #EAEAEA; /* This adds a subtle line under the footer */
  padding: 10px 20px; /* Adjust padding to match your design */
  display: flex;
  justify-content: center;
  /*margin: 0 auto;*/
}

.error {
  color: red;
}

.gray-container {
  width: calc(1200px - 20px); /* Fixed width */
  /*margin: 20px auto; !* Top and bottom margin with auto horizontal margins to center the block *!*/
  /* padding: 20px; *//* Optional padding inside the container */
  background-color: #f9f9f9; /* Optional background color */
  /*text-align: center;*/
}

.gray-container td,th {
  text-align: center;
}

.gray-container .left {
  text-align: left;
}

.gray-container .right {
  text-align: right;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
}

h2 {
  color: #004877;
  font-size: 28px;
}

span {
  font-size: 18px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container img {
  height: 30px; /* Adjust to the size of your logo */
  margin-right: 10px;
}

/*.container img {*/
/*  height: 50px; !* Adjust to the size of your logo *!*/
/*}*/

.header-footer-text {
  color: #FFFFFF;
}

.bold-note {
  font-weight: bold;
}

.pad-10 {
  padding: 10px;
}

.largish {
  font-size: 24px;
}

.smallish {
  font-size: 16px; /* Adjust to match your design */
}

.user-menu {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info img {
  border-radius: 50%; /* Makes the image round */
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  margin-right: 10px; /* Adjust as needed */
}

.user-info span {
  margin-right: 5px; /* Adjust as needed */
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: #FFFFFF;
}

form {
  margin: 20px 0;
}

.form-group {
  display: grid;
  grid-template-columns: minmax(150px, auto) 1fr; /* Adjust the first value as needed */
  grid-gap: 10px;
  margin-bottom: 20px;
  align-items: center;;
}

.form-group label {
}

.form-group .label-secondary {
  font-style: italic;
  font-size: 12px;
  color: #004877;
}

.form-group input[type="file"],
.form-group input[type="text"],
.form-group textarea,
.form-group button {
  box-sizing: border-box; /* Include padding and border in the element's total width */
  width: 100%; /* Force the element to be as wide as its grid column */
}

.form-group span {
  display: block;
  margin-top: 5px;
}

button {
  padding: 10px 20px;
  background-color: #004877;
  color: white;
  border: none;
  cursor: pointer;
  margin: 10px;
  border-radius: 10px; /* Optional: for rounded corners */
}

button:disabled {
  padding: 10px 20px;
  background-color: #888888;
  color: white;
  border: none;
  cursor: auto;
}

.custom-file-upload {
  padding: 10px 15px; /* Adjust the padding as needed */
  border: 1px solid #ccc;
  background-color: #f8f8f8; /* You can set a background color */
  color: #333; /* And a text color */
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 10px; /* Optional: for rounded corners */
  font-size: 1rem; /* Adjust the font size as needed */
  line-height: 1.5;
  min-width: 100px; /* Optional: set a minimum width */
  max-width: 100px;
  margin-right: 10px;
  display: inline-block;
  box-sizing: border-box; /* Include padding and border in the button's dimensions */
}

.order-form-input-text {
  max-width: 300px;
}

/*.order-form {*/
/*  width: 1000px;*/
/*}*/

.file-name {
  margin-left: 10px;
  color: #21A700;
  font-style: italic;
}


.table-responsive {
  overflow-x: auto; /* Allows table to scroll on small screens */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table thead th {
  background-color: #004877;
  color: #fff;
  text-align: left;
  padding: 10px;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

table tbody td {
  padding: 10px;
  border: 1px solid #f5f5f5;
}

table tbody tr {
  background-color: #ffffff;
}

.table-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-back,
.btn-next {
  padding: 10px 20px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.btn-back {
  background-color: #ccc;
}

.btn-next {
  background-color: #0052cc;
}

.user-section {
  position: relative;
  display: flex;
  align-items: center;
}

.user-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-width: 240px; /* You can set a minimum width that fits the longest expected email */
  padding: 8px 0; /* Add some vertical padding for aesthetics */
  border-radius: 4px; /* Optional: for rounded corners */
}

.dropdown-item {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #333;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Ensures the content is clipped if it overflows */
  text-overflow: ellipsis; /* Adds an ellipsis (...) if text overflows */
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

img.gravatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

/* endregion */